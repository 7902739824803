markdown {
  * {
    @apply text-gray-600;
  }

  h3 {
    @apply text-gray-800 mb-2;
  }

  del {
    text-decoration: none !important;
  }

  ul {
    @apply mb-4;

    & li p {
      @apply mb-2 text-base;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      background-color: #e5e7eb;
    }
  }
}

table {
  @apply overflow-hidden table-auto;

  thead {
    @apply bg-neutral-100;
  }

  td,
  th {
    @apply py-4 border border-gray-200;
  }

  th {
    @apply font-bold;
  }

  td {
    @apply text-center border border-gray-200;
  }

  tbody > tr {
    @apply border-neutral-200 hover:bg-neutral-50;

    &[ng-reflect-router-link] {
      @apply cursor-pointer;
    }
  }
}
