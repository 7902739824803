h1 {
  @apply text-3xl font-bold;
}

h2 {
  @apply text-2xl font-bold;
}

h3 {
  @apply text-xl font-semibold;
}

h4 {
  @apply text-lg font-semibold;
}

h5 {
  @apply text-base font-semibold;
}

h6 {
  @apply text-sm font-semibold;
}
