@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@angular/cdk/overlay-prebuilt.css';
@import 'ngx-toastr/toastr';

// custom
@import './themes/container.scss';
@import './themes/typography.scss';
@import './themes/fonts.scss';
@import './themes/scroll.scss';
@import './themes/animations.scss';
@import './themes/theme.scss';
@import './themes/markdown.scss';

* {
  font-family: 'SUIT';
  letter-spacing: -0.04em !important;
  outline: none;
}
